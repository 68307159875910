import CountContainer from '@components/shared/CountContainer'
import clsx from 'clsx'

interface Props {
  appliedFilter: string
  changeFilter: (val: string) => void
  options: { label: string; count: number }[]
  labelClasses?: string
  forModal?: boolean
}

const TokenFilters = ({
  appliedFilter,
  changeFilter,
  options,
  labelClasses,
  forModal = false,
}: Props) => {
  return (
    <div className="flex items-center space-x-2 overflow-scroll">
      {options.map((item, idx) => {
        const isSelected = appliedFilter === item.label
        return (
          <div
            key={`${item.label}-${idx}`}
            className={clsx(
              'z-10 flex h-8 min-w-fit items-center justify-center rounded border border-th-input-border-secondary px-2.5 transition-colors duration-150',
              isSelected
                ? forModal
                  ? 'bg-th-fgd-1'
                  : 'bg-th-bkg-3'
                : forModal
                  ? 'cursor-pointer bg-th-bkg-3'
                  : 'cursor-pointer bg-th-bkg-1',
            )}
            onClick={() => {
              if (isSelected) {
                return
              }
              changeFilter(item.label)
            }}
          >
            <p
              className={clsx(
                'flex items-center gap-2 text-sm font-medium transition-colors duration-150',
                isSelected
                  ? forModal
                    ? 'text-th-bkg-1'
                    : 'text-th-fgd-1'
                  : 'text-th-fgd-2',
                appliedFilter === 'LABS' &&
                  appliedFilter === item.label &&
                  '!text-th-active',
                labelClasses,
              )}
            >
              {item.label}
              {item.count !== 0 && (
                <CountContainer
                  value={item.count}
                  color={
                    appliedFilter === 'LABS' && appliedFilter === item.label
                      ? '#696EFF'
                      : isSelected
                        ? forModal
                          ? '#111113'
                          : '#fff'
                        : '#ffffffb3'
                  }
                  textClasses={
                    appliedFilter === 'LABS' && appliedFilter === item.label
                      ? 'text-th-active'
                      : isSelected
                        ? forModal
                          ? 'text-th-bkg-1'
                          : 'text-th-fgd-1'
                        : 'text-th-fgd-2'
                  }
                />
              )}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default TokenFilters
