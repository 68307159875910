import MarketChange from '@components/shared/MarketChange'
import { ShareBar } from '@components/shared/ShareBar'
import Tooltip from '@components/shared/Tooltip'
import ChartTokenSelector from '@components/trade/gmx/ChartTokenSelector'
import { tradeboxStoreBigNumberSelector } from '@store/tradeboxStore'
import clsx from 'clsx'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import {
  getToken,
  isChartAvailabeForToken,
  NATIVE_TOKEN_ADDRESS,
  Token,
} from 'config/tokens'
import {
  getBorrowingFactorPerPeriod,
  getFundingFactorPerPeriod,
} from 'domain/synthetics/fees'
import {
  getAvailableUsdLiquidityForPosition,
  getByKey,
  getMarketIndexName,
  getMarketPoolName,
  getMaxOpenInterestUsd,
  getMaxReservedUsd,
  getOpenInterestUsd,
  getReservedUsd,
  MarketInfo,
  useMarketsInfo,
} from 'domain/synthetics/markets'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { use24hPriceDelta } from 'domain/synthetics/tokens/use24PriceDelta'
import { AvailableTokenOptions, TradeType } from 'domain/synthetics/trade'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { helperToast } from 'gmx/lib/helperToast'
import { useViewport } from 'hooks/useViewport'
import { useTranslation } from 'next-i18next'
import InfoIcon from 'public/icons/info.svg'
import KeyboardIcon from 'public/icons/keyboard.svg'
import { useCallback, useEffect, useMemo } from 'react'
import LiveMarketPrice from './LiveMarketPrice'
import { DEFAULT_PERIOD } from './tradingview/constants'
import { CHART_PERIODS } from './tradingview/lib/legacy'
import { useLocalStorageSerializeKey } from './tradingview/lib/localStorage'
import {
  formatPercentage,
  formatUsd,
  getBasisPoints,
} from './tradingview/lib/numbers'

export const USD_DECIMALS = 30

export type TokenPrices = {
  minPrice: BigNumber
  maxPrice: BigNumber
}

export type TokenData = Token & {
  prices: TokenPrices
  balance?: BigNumber
  totalSupply?: BigNumber
}

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString()
  if (maxDecimals === undefined) {
    return amountStr
  }
  if (maxDecimals === 0) {
    return amountStr.split('.')[0]
  }
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(
        0,
        amountStr.length - (decimals - maxDecimals),
      )
    }
  }

  return amountStr
}

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString()
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(
        amountStr.length + (minDecimals - decimals),
        '0',
      )
    }
  } else {
    amountStr = amountStr + '.0000'
  }
  return amountStr
}

export function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return '...'
  }

  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string,
) => {
  if (!defaultValue) {
    defaultValue = '...'
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals)
  amountStr = limitDecimals(amountStr, displayDecimals)
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals)
  }
  if (useCommas) {
    return numberWithCommas(amountStr)
  }
  return amountStr
}

export type TokensData = {
  [address: string]: TokenData
}

export function getTokenData(
  tokensData?: TokensData,
  address?: string,
  convertTo?: 'wrapped' | 'native',
) {
  if (!address || !tokensData?.[address]) {
    return undefined
  }

  const token = tokensData[address]

  if (convertTo === 'wrapped' && token.isNative && token.wrappedAddress) {
    return tokensData[token.wrappedAddress]
  }

  if (convertTo === 'native' && token.isWrapped) {
    return tokensData[NATIVE_TOKEN_ADDRESS]
  }

  return token
}

interface Props {
  positionsInfo?: PositionsInfoData
  avaialbleTokenOptions: AvailableTokenOptions
  availableTokens?: Token[]
  tradeFlags: TradeFlags
  setToTokenAddress: (
    tokenAddress?: string,
    marketTokenAddress?: string,
    tradeType?: TradeType,
  ) => void
  tradeType: TradeType
  chartTokenAddress: string | undefined
  marketInfo: MarketInfo | undefined
  isLong: boolean
}

const MarketHeader = ({
  positionsInfo,
  avaialbleTokenOptions,
  availableTokens,
  tradeFlags,
  setToTokenAddress,
  tradeType,
  chartTokenAddress,
  marketInfo,
  isLong,
}: Props) => {
  const { isMobile } = useViewport()
  const { t } = useTranslation(['common', 'trade'])
  const { chainId } = useChainId()
  const { marketsInfoData, tokensData } = useMarketsInfo(chainId)

  let [period, setPeriod] = useLocalStorageSerializeKey(
    [chainId, 'Chart-period-v2'],
    DEFAULT_PERIOD,
  )
  const longLiquidityInfo = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.longLiquidity,
  )
  const shortLiquidityInfo = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.shortLiquidity,
  )
  const totalInterestUsd = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.totalInterestUsd,
  )

  if (!period || !(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD
  }

  const chartToken = getTokenData(tokensData, chartTokenAddress)

  const tokenOptions: Token[] | undefined = availableTokens?.filter((token) =>
    isChartAvailabeForToken(chainId, token.symbol),
  )

  const selectedTokenOption = useMemo(() => {
    if (!chartTokenAddress) {
      return undefined
    }

    return getToken(chainId, chartTokenAddress, false)
  }, [chainId, chartTokenAddress])
  const { priceDelta: dayPriceDelta, priceDeltas } = use24hPriceDelta(
    chainId,
    chartToken?.symbol,
  )

  function onSelectTokenOption(
    address: string,
    marketTokenAddress?: string,
    tradeType?: TradeType,
  ) {
    setToTokenAddress(address, marketTokenAddress, tradeType)

    if (marketTokenAddress) {
      const marketInfo = getByKey(marketsInfoData, marketTokenAddress)
      const nextTradeType = tradeType ?? tradeType
      if (nextTradeType === TradeType.Swap) {
        return
      }
      if (marketInfo && nextTradeType) {
        const indexName = getMarketIndexName(marketInfo)
        const poolName = getMarketPoolName(marketInfo)
        helperToast.success(
          <>
            <span>{nextTradeType === TradeType.Long ? t`Long` : t`Short`}</span>{' '}
            <div className="inline-flex">
              <span>{indexName}</span>
              <span className="subtext">[{poolName}]</span>
            </div>{' '}
            <span>market selected</span>
          </>,
        )
      }
    }
  }

  useEffect(
    function updatePeriod() {
      if (!period || !(period in CHART_PERIODS)) {
        setPeriod(DEFAULT_PERIOD)
      }
    },
    [period, setPeriod],
  )

  const fundingRate = useMemo(() => {
    return (
      marketInfo &&
      getFundingFactorPerPeriod(marketInfo, isLong, CHART_PERIODS['1h']).mul(
        100,
      )
    )
  }, [marketInfo, isLong])

  const { indexToken } = marketInfo || {}

  const {
    // liquidity,
    maxReservedUsd,
    reservedUsd,
    // borrowingRate,
    fundingRateLong,
    fundingRateShort,
    // priceDecimals,
    currentOpenInterest,
    maxOpenInterest,
  } = useMemo(() => {
    if (!marketInfo) {
      return {}
    }
    return {
      liquidity: getAvailableUsdLiquidityForPosition(marketInfo, isLong),
      maxReservedUsd: getMaxReservedUsd(marketInfo, isLong),
      reservedUsd: getReservedUsd(marketInfo, isLong),
      borrowingRate: getBorrowingFactorPerPeriod(
        marketInfo,
        isLong,
        CHART_PERIODS['1h'],
      ).mul(100),
      fundingRateLong: getFundingFactorPerPeriod(
        marketInfo,
        true,
        CHART_PERIODS['1h'],
      ).mul(100),
      fundingRateShort: getFundingFactorPerPeriod(
        marketInfo,
        false,
        CHART_PERIODS['1h'],
      ).mul(100),
      currentOpenInterest: getOpenInterestUsd(marketInfo, isLong),
      totalInterestUsd: marketInfo.longInterestUsd.add(
        marketInfo.shortInterestUsd,
      ),
      priceDecimals: marketInfo.indexToken.priceDecimals,
      maxOpenInterest: getMaxOpenInterestUsd(marketInfo, isLong),
    }
  }, [marketInfo, isLong])

  const renderFundingFeeTooltipContent = useCallback(() => {
    if (!fundingRateLong || !fundingRateShort) {
      return []
    }
    const isMarketWithAdaptiveFundingRate =
      marketInfo?.fundingIncreaseFactorPerSecond.gt(0)

    const isLongPositive = fundingRateLong?.gt(0)
    const long = (
      <>
        Long positions {isLongPositive ? `receive` : `pay`} a Funding Fee of{' '}
        <span className={isLongPositive ? 'text-th-success' : 'text-th-error'}>
          {isLongPositive ? '+' : '-'}
          {formatAmount(fundingRateLong.abs(), 30, 4)}%
        </span>{' '}
        per hour.
      </>
    )

    const isShortPositive = fundingRateShort?.gt(0)
    const short = (
      <>
        Short positions {isShortPositive ? `receive` : `pay`} a Funding Fee of{' '}
        <span className={isShortPositive ? 'text-th-success' : 'text-th-error'}>
          {isShortPositive ? '+' : '-'}
          {formatAmount(fundingRateShort.abs(), 30, 4)}%
        </span>{' '}
        per hour.
      </>
    )

    const [currentFeeElement, oppositeFeeElement] = isLong
      ? [long, short]
      : [short, long]

    return (
      <div>
        {currentFeeElement}
        <br />
        <br />
        {oppositeFeeElement}
        {isMarketWithAdaptiveFundingRate && (
          <>
            <br />
            <br />
            <>
              This market uses an Adaptive Funding Rate. The Funding Rate will
              adjust over time depending on the ratio of longs and shorts.
              <br />
              <br />
              {/* <ExternalLink href="https://docs.gmx.io/docs/trading/v2/#adaptive-funding">
                Read more
              </ExternalLink> */}
              .
            </>
          </>
        )}
      </div>
    )
  }, [fundingRateLong, fundingRateShort, isLong, marketInfo])

  const longShortText = isLong ? `Long` : `Short`

  if (isMobile) {
    return (
      <div className="relative space-y-1 px-4 pb-2">
        <div className="flex h-12 items-center justify-between">
          <ChartTokenSelector
            chainId={chainId}
            selectedToken={selectedTokenOption}
            onSelectToken={onSelectTokenOption}
            tradeFlags={tradeFlags}
            options={tokenOptions}
            avaialbleTokenOptions={avaialbleTokenOptions}
            positionsInfo={positionsInfo}
            tokensData={tokensData}
            priceDeltas={priceDeltas}
          />

          {chartToken?.pythId && (
            <div className="text-xl font-bold text-th-fgd-1">
              <LiveMarketPrice
                basePythId={chartToken.baseId}
                quotePythId={chartToken.pythId}
                tokenAddress={chartToken.address}
              />
            </div>
          )}
        </div>
        <div className="flex h-12 items-center justify-between">
          <div>
            <div className="text-xs font-normal text-th-fgd-3">
              {t('rolling-change')}
            </div>
            <MarketChange deltaPercentage={dayPriceDelta?.deltaPercentage} />
          </div>
          {tradeType !== TradeType.Swap && (
            <>
              <div className="flex flex-col items-end">
                <div className="text-xs font-normal text-th-fgd-3">
                  Funding Rate
                </div>

                <div
                  className={clsx(
                    'text-sm font-medium',
                    isLong ? 'text-th-success' : 'text-th-error',
                  )}
                >
                  {fundingRate
                    ? `${fundingRate.gt(0) ? '+' : '-'}${formatAmount(
                        fundingRate.abs(),
                        30,
                        4,
                      )}%`
                    : '...'}
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="text-xs font-normal text-th-fgd-3">
                  Available Liquidity
                </div>

                <div className="flex items-center gap-2">
                  <span className="text-th-success">
                    {(marketInfo && formatUsd(longLiquidityInfo)) || '...'}
                  </span>
                  <span className="text-th-error">
                    {(marketInfo && formatUsd(shortLiquidityInfo)) || '...'}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        {tradeType !== TradeType.Swap && (
          <div className="flex h-12 items-center justify-between">
            <div>
              <div className="text-xs font-normal text-th-fgd-3">
                Open Interest Balance
              </div>

              {marketInfo?.longInterestUsd
                .add(marketInfo?.shortInterestUsd)
                ?.gt(0) ? (
                <ShareBar
                  className="mb-2 mt-3"
                  share={marketInfo?.longInterestUsd}
                  total={marketInfo.longInterestUsd.add(
                    marketInfo.shortInterestUsd,
                  )}
                />
              ) : (
                '...'
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="relative flex h-12 justify-between">
      <div className="flex items-center gap-4 px-4">
        <ChartTokenSelector
          chainId={chainId}
          selectedToken={selectedTokenOption}
          onSelectToken={onSelectTokenOption}
          tradeFlags={tradeFlags}
          options={tokenOptions}
          avaialbleTokenOptions={avaialbleTokenOptions}
          positionsInfo={positionsInfo}
          tokensData={tokensData}
          priceDeltas={priceDeltas}
        />
        <div className="px-6">
          {chartToken?.pythId && (
            <div className="text-xl font-bold text-th-fgd-1">
              <LiveMarketPrice
                basePythId={chartToken.baseId}
                quotePythId={chartToken.pythId}
                tokenAddress={chartToken.address}
              />
            </div>
          )}
        </div>
        <div className="px-6">
          <div className="text-xs font-normal text-th-fgd-3">
            {t('rolling-change')}
          </div>
          <MarketChange deltaPercentage={dayPriceDelta?.deltaPercentage} />
        </div>

        {tradeType !== TradeType.Swap && (
          <>
            <div className="px-6">
              <div className="text-xs font-normal text-th-fgd-3">
                Funding Rate
              </div>

              <Tooltip
                className="al-swap"
                placement="top"
                content={renderFundingFeeTooltipContent()}
              >
                <div
                  className={clsx(
                    'text-sm font-medium',
                    isLong ? 'text-th-success' : 'text-th-error',
                  )}
                >
                  {fundingRate
                    ? `${fundingRate.gt(0) ? '+' : '-'}${formatAmount(
                        fundingRate.abs(),
                        30,
                        4,
                      )}%`
                    : '...'}
                </div>
              </Tooltip>
            </div>
            <div className="px-6">
              <div className="text-xs font-normal text-th-fgd-3">
                Available Liquidity
              </div>

              <Tooltip
                className="al-swap"
                placement="top"
                content={
                  <div>
                    <StatsTooltipRow
                      label={`${longShortText} ${indexToken?.symbol} Reserve`}
                      value={`${formatUsd(reservedUsd, {
                        displayDecimals: 0,
                      })} / ${formatUsd(maxReservedUsd, {
                        displayDecimals: 0,
                      })}`}
                      showDollar={false}
                    />
                    <StatsTooltipRow
                      label={`${longShortText} ${indexToken?.symbol} Open Interest`}
                      value={`${formatUsd(currentOpenInterest, {
                        displayDecimals: 0,
                      })} / ${formatUsd(maxOpenInterest, {
                        displayDecimals: 0,
                      })}`}
                      showDollar={false}
                    />

                    <br />
                    {isLong && (
                      <>
                        Reserve considers the PnL of Open Positions, while Open
                        Interest does not.
                      </>
                    )}
                    <>
                      The Available Liquidity will be the lesser of the
                      difference between the maximum value and the current value
                      for the Reserve and Open Interest.
                    </>
                  </div>
                }
              >
                <span className="mr-2 text-th-success">
                  {(marketInfo && formatUsd(longLiquidityInfo)) || '...'}
                </span>
                <span className="text-th-error">
                  {(marketInfo && formatUsd(shortLiquidityInfo)) || '...'}
                </span>
              </Tooltip>
            </div>
            <div className="px-6">
              <div className="text-xs font-normal text-th-fgd-3">
                Open Interest Balance
              </div>

              <Tooltip
                placement="bottom"
                content={
                  <div>
                    {marketInfo && totalInterestUsd && (
                      <>
                        <StatsTooltipRow
                          label={`Long Open Interest`}
                          value={
                            <span>
                              {formatUsd(marketInfo.longInterestUsd, {
                                displayDecimals: 0,
                              })}{' '}
                              <br />
                              {totalInterestUsd.gt(0) &&
                                `(${formatPercentage(
                                  getBasisPoints(
                                    marketInfo.longInterestUsd,
                                    totalInterestUsd,
                                  ),
                                )})`}
                            </span>
                          }
                          showDollar={false}
                        />
                        <br />
                        <StatsTooltipRow
                          label={`Short Open Interest`}
                          value={
                            <span>
                              {formatUsd(marketInfo.shortInterestUsd, {
                                displayDecimals: 0,
                              })}{' '}
                              <br />
                              {totalInterestUsd.gt(0) &&
                                `(${formatPercentage(
                                  getBasisPoints(
                                    marketInfo.shortInterestUsd,
                                    totalInterestUsd,
                                  ),
                                )})`}
                            </span>
                          }
                          showDollar={false}
                        />
                      </>
                    )}
                  </div>
                }
              >
                {marketInfo?.longInterestUsd
                  .add(marketInfo?.shortInterestUsd)
                  ?.gt(0) ? (
                  <ShareBar
                    className="mb-2 mt-3"
                    share={marketInfo?.longInterestUsd}
                    total={marketInfo.longInterestUsd.add(
                      marketInfo.shortInterestUsd,
                    )}
                  />
                ) : (
                  '...'
                )}
              </Tooltip>
            </div>
          </>
        )}
      </div>

      <div className="flex">
        <button className="flex h-full items-center border-l border-th-input-border px-6 text-xs font-normal leading-4 text-th-fgd-2">
          <KeyboardIcon />
        </button>
        <button className="flex h-full items-center gap-1 border-l border-th-input-border px-6 text-xs font-normal leading-4 text-th-fgd-2 hover:text-th-fgd-1">
          <InfoIcon />
          Market Details
        </button>
      </div>
    </div>
  )
}

export default MarketHeader
