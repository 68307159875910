import useLocalStorageState from 'hooks/useLocalStorageState'
import StarIconFilled from 'public/icons/star-filled.svg'
import StarIconLine from 'public/icons/star-line.svg'

const FavoriteMarketButton = ({
  market,
  localStorageKey,
  DefaultLocalStorageValue,
}: {
  market: string
  localStorageKey: string
  DefaultLocalStorageValue: string[]
}) => {
  const [favoriteMarkets, setFavoriteMarkets] = useLocalStorageState<string[]>(
    localStorageKey,
    DefaultLocalStorageValue,
  )

  const addToFavorites = (name: string) => {
    const newFavorites = [...favoriteMarkets, name]
    setFavoriteMarkets(newFavorites)
  }

  const removeFromFavorites = (name: string) => {
    setFavoriteMarkets(favoriteMarkets.filter((m: string) => m !== name))
  }

  return favoriteMarkets?.find((name: string) => name === market) ? (
    <button
      className="flex items-center justify-center text-th-active focus-visible:text-th-fgd-3 md:hover:text-th-fgd-3"
      onClick={() => removeFromFavorites(market)}
    >
      <StarIconFilled />
    </button>
  ) : (
    <button
      className="flex items-center justify-center text-th-fgd-3 focus-visible:text-th-active md:hover:text-th-active"
      onClick={() => addToFavorites(market)}
    >
      <StarIconLine />
    </button>
  )
}

export default FavoriteMarketButton
