import priceStore from '@store/priceStore'
import { formatPrice } from 'apis/birdeye/helpers'
import { BigNumber, ethers } from 'ethers'
import { PriceType } from 'hooks/gmx/usePyth/useTokenPrice'
import React, { memo } from 'react'

type Props = {
  quotePythId: string
  basePythId?: string
  tokenAddress: string
  formatter?: (price: BigNumber) => string
  priceType?: PriceType
  widthBuffer?: number
  className?: string
  textContentOnly?: boolean
}

export const USD_DECIMALS = 30

const formatUnits = (price: BigNumber) =>
  parseFloat(ethers.utils.formatUnits(price || 0, USD_DECIMALS))

const defaultFormatter = (price: BigNumber) =>
  '$' + formatPrice(formatUnits(price))

const LiveMarketPrice: React.FC<Props> = ({
  quotePythId,
  basePythId,
  priceType = 'maxPrice',
  formatter = defaultFormatter,
}: Props) => {
  const v = priceStore((state) => {
    const prices = ([quotePythId, basePythId].filter(Boolean) as string[]).map(
      (x) => state.prices[x],
    )

    if (prices.length === 1) {
      return prices[0]?.[priceType] || BigNumber.from('0')
    }

    const a = prices[0]
    const b = prices[1]

    if (!a || !b) {
      return BigNumber.from('0')
    }

    try {
      const price = +a[priceType].toString() / +b[priceType].toString()

      const x = ethers.utils.parseUnits(price.toString(), 30)
      return x
    } catch (e) {
      return BigNumber.from('0')
    }
  })

  return <>{formatter(v)}</>
}

export default memo(LiveMarketPrice)
