import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import { ReactNode } from 'react'

interface TableRowAccordianProps {
  VisibleContent: string | ReactNode
  HiddenContent: string | ReactNode
  SubRowContent?: string | ReactNode
  handleOnClick?: () => void
}

const TableRowAccordian = ({
  VisibleContent,
  HiddenContent,
  SubRowContent,
  handleOnClick,
}: TableRowAccordianProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div onClick={handleOnClick}>
          <div className="flex w-full items-center justify-between px-4">
            <div className="flex-1">{VisibleContent}</div>
            <Disclosure.Button
              className="cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                alt="arrow-down"
                width={16}
                height={16}
                src="/icons/arrow-down-grey.svg"
                className={clsx(open ? 'rotate-180' : 'rotate-0')}
              />
            </Disclosure.Button>
          </div>
          {Boolean(SubRowContent && !open) && (
            <div className="flex justify-end border-b border-th-input-border px-4">
              {SubRowContent}
            </div>
          )}
          <Disclosure.Panel className="px-4">{HiddenContent}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default TableRowAccordian
