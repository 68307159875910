import cx from 'classnames'
import { BigNumber, BigNumberish } from 'ethers'

interface Props {
  share?: BigNumberish
  total?: BigNumberish
  warningThreshold?: number // 0-100
  className?: string
  fillClassses?: string
}

export function ShareBar({
  className,
  fillClassses,
  share,
  total,
  warningThreshold,
}: Props) {
  if (!share || !total || BigNumber.from(total).isZero()) {
    return null
  }

  const progress = Math.min(
    BigNumber.from(share).mul(100).div(total).toNumber(),
    100,
  )

  return (
    <div
      className={cx(
        'relative h-0.5 w-full min-w-[64px] bg-th-error',
        className,
        warningThreshold && warningThreshold < progress ? 'warning' : null,
      )}
    >
      <div
        className={cx('h-full bg-th-success', fillClassses)}
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}
