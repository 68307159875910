import {
  ChartingLibraryFeatureset,
  ChartingLibraryWidgetOptions,
} from '@public/charting_library/charting_library'
import { formatPrice } from 'apis/birdeye/helpers'
import { ARBITRUM, AVALANCHE, ZKSYNC, ZKSYNC_SEPOLIA } from 'config/chains'
import { addMinutes, format as formatDateFn } from 'date-fns'

export function formatTVDate(date: Date) {
  // https://github.com/date-fns/date-fns/issues/1401#issuecomment-578580199
  return formatDateFn(addMinutes(date, date.getTimezoneOffset()), 'dd MMM yyyy')
}

export function formatTVTime(date: Date) {
  return formatDateFn(addMinutes(date, date.getTimezoneOffset()), 'h:mm a')
}
const RED = '#fa3c58'
const GREEN = '#0ecc83'
export const DEFAULT_PERIOD = '4h'

const chartStyleOverrides = [
  'candleStyle',
  'hollowCandleStyle',
  'haStyle',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
].reduce((acc: any, cv) => {
  acc[`mainSeriesProperties.${cv}.drawWick`] = true
  acc[`mainSeriesProperties.${cv}.drawBorder`] = false
  acc[`mainSeriesProperties.${cv}.upColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.downColor`] = RED
  acc[`mainSeriesProperties.${cv}.wickUpColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.wickDownColor`] = RED
  acc[`mainSeriesProperties.${cv}.borderUpColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.borderDownColor`] = RED
  return acc
}, {})

const chartOverrides = {
  // 'paneProperties.background': '#0000000',
  // 'paneProperties.backgroundGradientStartColor': '#0000000',
  // 'paneProperties.backgroundGradientEndColor': '#0000000',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.vertGridProperties.color': 'rgba(35, 38, 59, 1)',
  'paneProperties.vertGridProperties.style': 2,
  'paneProperties.horzGridProperties.color': 'rgba(35, 38, 59, 1)',
  'paneProperties.horzGridProperties.style': 2,
  'mainSeriesProperties.priceLineColor': '#3a3e5e',
  'scalesProperties.textColor': '#fff',
  'scalesProperties.lineColor': '#16182e',
  ...chartStyleOverrides,
}

export const disabledFeaturesOnMobile: ChartingLibraryFeatureset[] = [
  'header_saveload',
  'header_fullscreen_button',
]

const disabledFeatures: ChartingLibraryFeatureset[] = [
  'volume_force_overlay',
  // 'show_logo_on_all_charts',
  // 'caption_buttons_text_if_possible',
  'create_volume_indicator_by_default',
  'header_compare',
  // 'compare_symbol',
  'display_market_status',
  // 'header_interval_dialog_button',
  'show_interval_dialog_on_key_press',
  'header_symbol_search',
  'popup_hints',
  'header_in_fullscreen_mode',
  'use_localstorage_for_settings',
  // 'right_bar_stays_ons_scroll',
  'symbol_info',
  // 'timeframes_toolbar',
  'header_undo_redo',
]

const enabledFeatures: ChartingLibraryWidgetOptions['enabled_features'] = [
  'side_toolbar_in_fullscreen_mode',
  'header_in_fullscreen_mode',
  'hide_resolution_in_legend',
  'items_favoriting',
  'study_templates',
  'tick_resolution',
]

export const defaultChartProps: Omit<
  ChartingLibraryWidgetOptions,
  'datafeed' | 'interval'
> = {
  theme: 'dark',
  locale: 'en',
  container: 'tv_chart_container',
  library_path: '/charting_library/',
  client_id: 'rfx.exchange',
  user_id: 'public_user_id',
  fullscreen: false,
  autosize: true,
  overrides: chartOverrides,
  enabled_features: enabledFeatures,
  disabled_features: disabledFeatures,
  custom_css_url: '/styles/tradingview.css',
  loading_screen: {
    backgroundColor: 'transparent',
    foregroundColor: '#2962ff',
  },
  favorites: {},
  studies_overrides: {
    // 'volume.volume.color.0': COLORS.DOWN[theme],
    // 'volume.volume.color.1': COLORS.UP[theme],
    'volume.precision': 4,
  },
  custom_formatters: {
    timeFormatter: {
      format: (date) => formatTVTime(date),
      formatLocal: (date) => formatTVTime(date),
      // @ts-ignore
      parse: (str: string) => str,
    },
    dateFormatter: {
      format: (date) => formatTVDate(date),
      formatLocal: (date) => formatTVTime(date),
      // @ts-ignore
      parse: (str: string) => str,
    },
    // @ts-ignore
    priceFormatterFactory: () => {
      return {
        format: (price) => {
          // return the appropriate format
          return formatPrice(price)
        },
      }
    },
  },
}

export const availableNetworksForChart = [
  ARBITRUM,
  AVALANCHE,
  ZKSYNC_SEPOLIA,
  ZKSYNC,
]

export const SeriesType = {
  Bars: 0,
  Candles: 1,
  Line: 2,
  Area: 3,
  HeikenAshi: 8,
  HollowCandles: 9,
  Baseline: 10,
  HiLo: 12,
  Column: 13,
  LineWithMarkers: 14,
  Stepline: 15,
  HLCArea: 16,
  VolCandle: 19,
  Kagi: 5,
  PointAndFigure: 6,
  LineBreak: 7,
} as const

export type SeriesType = (typeof SeriesType)[keyof typeof SeriesType]

export const TimeFrameType = {
  PeriodBack: 'period-back',
  TimeRange: 'time-range',
} as const

export type TimeFrameType = (typeof TimeFrameType)[keyof typeof TimeFrameType]
