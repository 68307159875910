import EmptyState from '@components/shared/EmptyState'
import FavoriteMarketButton from '@components/shared/FavoriteMarketButton'
import MarketChange from '@components/shared/MarketChange'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TokenFilters from '@components/shared/TokenFilters'
import TableRowAccordian from '@components/TableRowAccordian'
import LiveMarketPrice from '@components/trade/gmx/LiveMarketPrice'
import { Popover } from '@headlessui/react'
import clsx from 'clsx'
import SearchInput from 'components/SearchInput/SearchInput'
import { CLOUD_FRONT_URL } from 'config/constants'
import { convertTokenAddress } from 'config/tokens'
import { getAvailableUsdLiquidityForPosition } from 'domain/synthetics/markets'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { AvailableTokenOptions, TradeType } from 'domain/synthetics/trade'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'
import { Token } from 'domain/tokens'
import { BigNumber, ethers } from 'ethers'
import { formatUsd } from 'gmx/lib/numbers'
import { useViewport } from 'hooks/useViewport'
import { isUndefined, keyBy } from 'lodash'
import groupBy from 'lodash/groupBy'
import Image from 'next/image'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKETS_KEY } from 'utils/constants'

type TokenOption = Token & {
  maxLongLiquidity: BigNumber
  maxShortLiquidity: BigNumber
  marketTokenAddress: string
  indexTokenAddress: string
}

type Props = {
  chainId: number
  selectedToken: Token | undefined
  onSelectToken: (
    address: string,
    marketAddress?: string,
    tradeType?: TradeType,
  ) => void
  tradeFlags?: TradeFlags
  options: Token[] | undefined
  className?: string
  avaialbleTokenOptions: AvailableTokenOptions
  positionsInfo?: PositionsInfoData
  tokensData: any
  priceDeltas: any
}

export const tokenFilters: Record<string, { label: string; count: number }> = {
  All: {
    label: 'All',
    count: 0,
  },
  New: {
    label: 'New',
    count: 0,
  },
  Crypto: {
    label: 'Crypto',
    count: 0,
  },
  Forex: {
    label: 'Forex',
    count: 0,
  },
  Commodities: {
    label: 'Commodities',
    count: 0,
  },
}

function scrollRowIntoView(keyDirection: 'up' | 'down') {
  const row = document.querySelector('.selected-row')
  const tbody = document.querySelector('.token-table')

  if (!row || !tbody) {
    return
  }

  const rowRect = row.getBoundingClientRect()
  const tbodyRect = tbody.getBoundingClientRect()

  if (keyDirection === 'down' && rowRect.bottom > tbodyRect.bottom - 40) {
    tbody.scrollBy({ top: rowRect.height })
    return
  }

  if (keyDirection === 'up' && rowRect.top < tbodyRect.top + 40) {
    tbody.scrollBy({ top: -rowRect.height })
    return
  }
}

export default function ChartTokenSelector(props: Props) {
  const {
    chainId,
    options,
    selectedToken,
    onSelectToken,
    tradeFlags,
    avaialbleTokenOptions,
    positionsInfo,
    tokensData,
    priceDeltas,
  } = props
  const { sortedAllMarkets } = avaialbleTokenOptions
  const { isSwap, isLong, isShort } = tradeFlags || {}
  const [searchKeyword, setSearchKeyword] = useState('')
  const [appliedTokenFilter, setAppliedTokenFilter] = useState(
    tokenFilters['All'].label,
  )
  const [selectedRow, setSelectedRow] = useState(0)
  const { isMobile } = useViewport()
  const onSelect = (token: {
    indexTokenAddress: string
    marketTokenAddress?: string
    tradeType?: TradeType
  }) => {
    onSelectToken(
      token.indexTokenAddress,
      token.marketTokenAddress,
      token.tradeType,
    )
    setSearchKeyword('')
  }

  const filteredTokens: Token[] | undefined = useMemo(() => {
    if (
      appliedTokenFilter !== tokenFilters['All'].label &&
      appliedTokenFilter !== tokenFilters['New'].label &&
      appliedTokenFilter !== tokenFilters['Crypto'].label
    ) {
      return []
    }

    return options?.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
        item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
      )
    })
  }, [appliedTokenFilter, options, searchKeyword])

  const updateTokenFiltersCount = useMemo(() => {
    const updatedFilters = { ...tokenFilters }
    Object.keys(updatedFilters).forEach((key) => {
      switch (key) {
        case 'All':
        case 'New':
        case 'Crypto':
          updatedFilters[key].count = options?.length || 0
          break

        case 'Forex':
        case 'Commodities':
          updatedFilters[key].count = 0
          break
        default:
          break
      }
    })
    return Object.keys(updatedFilters).map((item) => {
      return {
        label: updatedFilters[item].label,
        count: updatedFilters[item].count,
      }
    })
  }, [options])

  const groupedIndexMarkets = useMemo(() => {
    const marketsWithMaxReservedUsd = sortedAllMarkets.map((marketInfo) => {
      const maxLongLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        true,
      )
      const maxShortLiquidity = getAvailableUsdLiquidityForPosition(
        marketInfo,
        false,
      )

      return {
        maxLongLiquidity: maxLongLiquidity.gt(0)
          ? maxLongLiquidity
          : BigNumber.from(0),
        maxShortLiquidity: maxShortLiquidity.gt(0)
          ? maxShortLiquidity
          : BigNumber.from(0),
        marketTokenAddress: marketInfo.marketTokenAddress,
        indexTokenAddress: marketInfo.indexTokenAddress,
      }
    })
    const groupedMarketsWithIndex: { [marketAddress: string]: TokenOption[] } =
      groupBy(
        marketsWithMaxReservedUsd as any,
        (market) => market.indexTokenAddress,
      )

    return groupedMarketsWithIndex
  }, [sortedAllMarkets])

  function handleMarketSelect(
    token: Token,
    maxLongLiquidityPool: TokenOption,
    maxShortLiquidityPool: TokenOption,
  ) {
    const tokenAddress = convertTokenAddress(chainId, token.address, 'wrapped')

    if (tokenAddress === selectedToken?.address) {
      return
    }

    if (tradeFlags?.isSwap) {
      onSelect({
        indexTokenAddress: token.address,
      })
      return
    }

    const currentExistingPositions = Object.values(positionsInfo || {}).filter(
      (position) => {
        if (position.isLong === isLong) {
          return (
            convertTokenAddress(
              chainId,
              position.marketInfo.indexTokenAddress,
              'wrapped',
            ) === tokenAddress
          )
        }
        return false
      },
    )

    let marketTokenAddress
    const largestExistingPosition =
      Array.isArray(currentExistingPositions) && currentExistingPositions.length
        ? currentExistingPositions.reduce((max, current) =>
            max.sizeInUsd.gt(current.sizeInUsd) ? max : current,
          )
        : undefined

    if (largestExistingPosition) {
      marketTokenAddress =
        largestExistingPosition?.marketInfo.marketTokenAddress
    } else {
      if (isLong) {
        marketTokenAddress = maxLongLiquidityPool?.marketTokenAddress
      }

      if (isShort) {
        marketTokenAddress = maxShortLiquidityPool?.marketTokenAddress
      }
    }

    onSelect({
      indexTokenAddress: token.address,
      marketTokenAddress,
    })
  }

  function getMaxLongShortLiquidityPool(token: Token) {
    const indexTokenAddress = token.isNative
      ? token.wrappedAddress
      : token.address
    const currentMarkets =
      groupedIndexMarkets[
        ethers.utils.getAddress(indexTokenAddress?.toLowerCase()!)
      ]
    const maxLongLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxLongLiquidity || !current.maxLongLiquidity) {
        return current
      }
      return prev.maxLongLiquidity.gt(current.maxLongLiquidity) ? prev : current
    })

    const maxShortLiquidityPool = currentMarkets?.reduce((prev, current) => {
      if (!prev.maxShortLiquidity || !current.maxShortLiquidity) {
        return current
      }
      return prev.maxShortLiquidity.gt(current.maxShortLiquidity)
        ? prev
        : current
    })
    return {
      maxLongLiquidityPool,
      maxShortLiquidityPool,
    }
  }

  const tokenFilterChangeHandler = useCallback((val: string) => {
    setAppliedTokenFilter(val)
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          setSelectedRow((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
          scrollRowIntoView('up')
          break
        case 'ArrowDown':
          setSelectedRow((prevIndex) =>
            filteredTokens?.length && prevIndex < filteredTokens?.length
              ? prevIndex + 1
              : prevIndex,
          )

          scrollRowIntoView('down')
          break
        case 'Enter':
          if (isUndefined(filteredTokens)) {
            return
          }
          const token = filteredTokens[selectedRow - 1]
          const { maxLongLiquidityPool, maxShortLiquidityPool } =
            getMaxLongShortLiquidityPool(token)
          handleMarketSelect(token, maxLongLiquidityPool, maxShortLiquidityPool)
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedRow, filteredTokens])

  return (
    <Popover>
      {({ close, open }) => {
        if (!open && searchKeyword.length > 0) {
          setSearchKeyword('')
        }
        return (
          <>
            <Popover.Button
              as="button"
              className="inline-flex items-center gap-3 border-none bg-transparent text-th-fgd-1"
            >
              {selectedToken && (
                <div className="flex h-full w-max items-center gap-3">
                  <RenderTokenIcon symbol={selectedToken.symbol} />

                  <span className="text-xl font-bold uppercase text-th-fgd-1">
                    {selectedToken.symbol}
                    {!selectedToken.symbol.includes('/') && '/USD'}
                  </span>
                </div>
              )}
              <FaChevronDown
                fontSize={12}
                className={clsx(open && 'rotate-180')}
              />
            </Popover.Button>

            <Popover.Panel
              as="div"
              className={clsx(
                'absolute left-0 top-12 h-[646px] w-full max-w-[51rem] rounded-md',
                isMobile ? 'mt-1.5' : 'mt-[1px]',
              )}
            >
              <div
                className="absolute left-0 top-0 z-10 h-full w-full rounded-md bg-th-bkg-5 opacity-80"
                style={{
                  opacity: 0.8,
                }}
              />

              <div className="market-token-selector-glass-bg absolute left-0 top-0 z-10 h-full w-full" />

              <Image
                alt=""
                className="pointer-events-none absolute z-20 h-full w-full"
                layout="fill"
                src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
              />

              <div className="relative z-10 h-full p-4">
                <p className="mb-4 block border-b border-th-input-border pb-4 text-base font-bold text-th-fgd-1 sm:hidden">
                  Token Selector
                </p>
                <SearchInput
                  className="h-10 sm:h-12"
                  inputBoxClasses="h-12"
                  value={searchKeyword}
                  setValue={({ target }) => setSearchKeyword(target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      filteredTokens &&
                      filteredTokens.length > 0
                    ) {
                      const token = filteredTokens[0]
                      const { maxLongLiquidityPool, maxShortLiquidityPool } =
                        getMaxLongShortLiquidityPool(token)
                      handleMarketSelect(
                        token,
                        maxLongLiquidityPool,
                        maxShortLiquidityPool,
                      )
                      close()
                    }
                  }}
                />

                <div className="mb-4 mt-3">
                  <TokenFilters
                    appliedFilter={appliedTokenFilter}
                    changeFilter={tokenFilterChangeHandler}
                    options={updateTokenFiltersCount}
                    forModal
                  />
                </div>

                <div className="h-80 sm:h-[516px]">
                  {filteredTokens?.length === 0 ? (
                    <div className="flex h-full w-full items-center justify-center">
                      <EmptyState text="No Tokens Available" />
                    </div>
                  ) : (
                    <table className="h-full w-full border-collapse text-left text-sm">
                      {!isMobile && (
                        <thead className="table h-8 w-full table-fixed">
                          <tr className="text-sm text-th-fgd-2">
                            <th className="font-medium">MARKET</th>
                            <th className="font-medium">PRICE</th>
                            <th className="font-medium">24H CHANGE</th>
                            {!isSwap && (
                              <th className="font-medium">LONG LIQ.</th>
                            )}

                            {!isSwap && (
                              <th className="font-medium">SHORT LIQ.</th>
                            )}

                            <th className="w-[26px]"></th>
                          </tr>
                        </thead>
                      )}
                      <tbody
                        className={clsx(
                          'token-table block overflow-y-scroll',
                          isMobile ? 'h-full' : 'h-[calc(100%-32px)]',
                        )}
                      >
                        {filteredTokens?.map((token, idx) => {
                          const keyBySymbol = keyBy(priceDeltas, 'tokenSymbol')
                          const {
                            maxLongLiquidityPool,
                            maxShortLiquidityPool,
                          } = getMaxLongShortLiquidityPool(token)
                          return (
                            <Popover.Button
                              as="tr"
                              onMouseEnter={() => setSelectedRow(0)}
                              key={token.symbol}
                              className={clsx(
                                'table w-full table-fixed cursor-pointer',
                                !isMobile && 'h-12 hover:bg-th-bkg-2',
                                selectedRow === idx + 1 &&
                                  'selected-row bg-th-bkg-2',
                              )}
                            >
                              {isMobile ? (
                                <TableRowAccordian
                                  VisibleContent={
                                    <div className="flex items-start justify-between py-4">
                                      <div
                                        onClick={() =>
                                          handleMarketSelect(
                                            token,
                                            maxLongLiquidityPool,
                                            maxShortLiquidityPool,
                                          )
                                        }
                                      >
                                        <span className="text-[10px] font-medium text-th-fgd-3">
                                          MARKET
                                        </span>

                                        <div className="flex items-center gap-2 text-sm font-semibold text-th-fgd-1">
                                          <RenderTokenIcon
                                            symbol={token.symbol}
                                          />
                                          {token.symbol}
                                          {!isSwap &&
                                            !token.symbol.includes('/') &&
                                            '/USD'}
                                        </div>
                                      </div>

                                      <div className="mr-2 flex items-start gap-4">
                                        <div
                                          className="flex flex-col"
                                          onClick={() =>
                                            handleMarketSelect(
                                              token,
                                              maxLongLiquidityPool,
                                              maxShortLiquidityPool,
                                            )
                                          }
                                        >
                                          <span className="text-[10px] font-medium text-th-fgd-3">
                                            PRICE
                                          </span>
                                          <span className="text-sm font-medium text-th-fgd-1">
                                            <LiveMarketPrice
                                              tokenAddress={token.address}
                                              quotePythId={
                                                tokensData[token.address].pythId
                                              }
                                              basePythId={
                                                tokensData[token.address].baseId
                                              }
                                            />
                                          </span>
                                        </div>
                                        <div
                                          className="flex flex-col"
                                          onClick={() => {
                                            onSelect({
                                              indexTokenAddress: token.address,
                                              marketTokenAddress:
                                                maxLongLiquidityPool?.marketTokenAddress,
                                              tradeType: TradeType.Long,
                                            })
                                          }}
                                        >
                                          <span className="text-[10px] font-medium text-th-fgd-3">
                                            LONG LIQ.
                                          </span>
                                          <span className="text-sm font-medium text-th-fgd-1">
                                            {!isSwap && maxLongLiquidityPool
                                              ? formatUsd(
                                                  maxLongLiquidityPool?.maxLongLiquidity,
                                                )
                                              : ''}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  HiddenContent={
                                    <div className="flex justify-between pb-4">
                                      <div
                                        className="flex flex-col"
                                        onClick={() =>
                                          handleMarketSelect(
                                            token,
                                            maxLongLiquidityPool,
                                            maxShortLiquidityPool,
                                          )
                                        }
                                      >
                                        <span className="text-[10px] font-medium text-th-fgd-3">
                                          24H CHANGE
                                        </span>
                                        <MarketChange
                                          deltaPercentage={
                                            keyBySymbol[token.symbol]
                                              ?.deltaPercentage
                                          }
                                          size="small"
                                        />
                                      </div>

                                      <div className="flex items-center gap-6">
                                        <div
                                          className="flex flex-col"
                                          onClick={() => {
                                            onSelect({
                                              indexTokenAddress: token.address,
                                              marketTokenAddress:
                                                maxShortLiquidityPool?.marketTokenAddress,
                                              tradeType: TradeType.Short,
                                            })
                                          }}
                                        >
                                          <span className="text-[10px] font-medium text-th-fgd-3">
                                            SHORT LIQ.
                                          </span>
                                          <span className="text-sm font-medium text-th-fgd-1">
                                            {!isSwap && maxShortLiquidityPool
                                              ? formatUsd(
                                                  maxShortLiquidityPool?.maxShortLiquidity,
                                                )
                                              : ''}
                                          </span>
                                        </div>

                                        <div
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <FavoriteMarketButton
                                            market={token.symbol}
                                            localStorageKey={
                                              FAVORITE_MARKETS_KEY
                                            }
                                            DefaultLocalStorageValue={
                                              DEFAULT_FAVORITE_MKTS
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  }
                                />
                              ) : (
                                <>
                                  <td
                                    onClick={() =>
                                      handleMarketSelect(
                                        token,
                                        maxLongLiquidityPool,
                                        maxShortLiquidityPool,
                                      )
                                    }
                                    className="text-sm font-medium text-th-fgd-1"
                                  >
                                    <div className="flex items-center gap-2">
                                      <RenderTokenIcon symbol={token.symbol} />

                                      {token.symbol}
                                      {!isSwap &&
                                        !token.symbol.includes('/') &&
                                        '/USD'}
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleMarketSelect(
                                        token,
                                        maxLongLiquidityPool,
                                        maxShortLiquidityPool,
                                      )
                                    }
                                  >
                                    <LiveMarketPrice
                                      tokenAddress={token.address}
                                      quotePythId={
                                        tokensData[token.address].pythId
                                      }
                                      basePythId={
                                        tokensData[token.address].baseId
                                      }
                                    />
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleMarketSelect(
                                        token,
                                        maxLongLiquidityPool,
                                        maxShortLiquidityPool,
                                      )
                                    }
                                  >
                                    <MarketChange
                                      deltaPercentage={
                                        keyBySymbol[token.symbol]
                                          ?.deltaPercentage
                                      }
                                      size="small"
                                      showBg={true}
                                    />
                                  </td>
                                  {!isSwap && (
                                    <td
                                      onClick={() => {
                                        onSelect({
                                          indexTokenAddress: token.address,
                                          marketTokenAddress:
                                            maxLongLiquidityPool?.marketTokenAddress,
                                          tradeType: TradeType.Long,
                                        })
                                      }}
                                    >
                                      {maxLongLiquidityPool
                                        ? formatUsd(
                                            maxLongLiquidityPool?.maxLongLiquidity,
                                          )
                                        : ''}
                                    </td>
                                  )}

                                  {!isSwap && (
                                    <td
                                      onClick={() => {
                                        onSelect({
                                          indexTokenAddress: token.address,
                                          marketTokenAddress:
                                            maxShortLiquidityPool?.marketTokenAddress,
                                          tradeType: TradeType.Short,
                                        })
                                      }}
                                    >
                                      {maxShortLiquidityPool
                                        ? formatUsd(
                                            maxShortLiquidityPool?.maxShortLiquidity,
                                          )
                                        : ''}
                                    </td>
                                  )}

                                  <td
                                    className="w-[26px]"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <FavoriteMarketButton
                                      market={token.symbol}
                                      localStorageKey={FAVORITE_MARKETS_KEY}
                                      DefaultLocalStorageValue={
                                        DEFAULT_FAVORITE_MKTS
                                      }
                                    />
                                  </td>
                                </>
                              )}
                            </Popover.Button>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </>
        )
      }}
    </Popover>
  )
}
