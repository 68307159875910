import { MinusSmallIcon } from '@heroicons/react/20/solid'
import { DownTriangle, UpTriangle } from './DirectionTriangles'
import FormatNumericValue from './FormatNumericValue'
import { useMemo } from 'react'
import SheenLoader from './SheenLoader'
import clsx from 'clsx'

const MarketChange = ({
  deltaPercentage,
  size,
  showBg = false,
}: {
  deltaPercentage: number | undefined
  size?: 'small'
  showBg?: boolean
}) => {
  const change = useMemo(() => {
    if (deltaPercentage && deltaPercentage > 0) {
      return deltaPercentage
    }
    if (deltaPercentage && deltaPercentage < 0) {
      return deltaPercentage
    }
  }, [deltaPercentage])

  return !deltaPercentage ? (
    <SheenLoader className="mt-0.5">
      <div className="h-3.5 w-12 bg-th-bkg-2" />
    </SheenLoader>
  ) : change && !isNaN(change) ? (
    <div
      className={clsx(
        'flex items-center justify-start space-x-1.5',
        showBg && (change >= 0 ? 'bg-th-up-dark' : 'bg-th-down-dark'),
        showBg && 'w-fit rounded p-2',
      )}
    >
      {change > 0 ? (
        <div className="mt-[1px]">
          <UpTriangle size={size} />
        </div>
      ) : change < 0 ? (
        <div className="mt-[1px]">
          <DownTriangle size={size} />
        </div>
      ) : (
        <MinusSmallIcon
          className={`-mr-1 ${
            size === 'small' ? 'h-4 w-4' : 'h-6 w-6'
          } text-th-fgd-3`}
        />
      )}
      <span
        className={`font-flexo font-normal ${
          size === 'small' ? 'text-xs' : 'text-sm'
        } ${
          change > 0
            ? 'text-th-success'
            : change < 0
              ? 'text-th-error'
              : 'text-th-fgd-3'
        }`}
      >
        <FormatNumericValue
          value={isNaN(change) ? '0.00' : Math.abs(change)}
          decimals={2}
        />
        %
      </span>
    </div>
  ) : (
    <div className="flex items-center space-x-1.5">
      <MinusSmallIcon
        className={`-mr-1 ${
          size === 'small' ? 'h-4 w-4' : 'h-6 w-6'
        } text-th-fgd-3`}
      />
      <p
        className={`font-flexo font-normal ${
          size === 'small' ? 'text-xs' : 'text-sm'
        } text-th-fgd-2`}
      >
        0%
      </p>
    </div>
  )
}

export default MarketChange
