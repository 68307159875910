import clsx from 'clsx'
import Image from 'next/image'
import { ChangeEventHandler, KeyboardEvent } from 'react'
import { useMedia } from 'react-use'

type Props = {
  value: string
  setValue: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  className?: string
  placeholder?: string
  inputBoxClasses?: string
  as?: React.ElementType
}

export default function SearchInput({
  value,
  setValue,
  onKeyDown,
  className,
  placeholder,
  inputBoxClasses,
  as: Tag = 'div',
}: Props) {
  const isSmallerScreen = useMedia('(max-width: 700px)', false)

  return (
    <Tag
      className={clsx(
        'flex items-center gap-2 rounded-md border border-th-input-border-secondary bg-th-bkg-3 px-4',
        className,
      )}
    >
      <Image
        alt="search address"
        width={24}
        height={24}
        src="/icons/search-normal.svg"
      />
      <input
        id="searchInputElement"
        type="text"
        autoComplete="off"
        placeholder={placeholder ?? `Search Token`}
        value={value}
        onChange={setValue}
        onKeyDown={onKeyDown}
        autoFocus={!isSmallerScreen}
        className={clsx(
          'w-full bg-transparent text-sm font-normal text-th-fgd-2 outline-none sm:text-base',
          inputBoxClasses,
        )}
      />
    </Tag>
  )
}
